import React from "react"

//React components imports
import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"

//Styled components imports
import Title from "../../shared-styled-components/Title"
import Date from "./Date"
import Content from "./Content"

const ArticleTemplate = ({ pageContext, location, data }) => {
  return (
    <Layout>
      <Breadcrumbs
        data={{
          path: location.pathname.replace("/en", ""),
          breadEnd: pageContext.data.title,
        }}
      />
      <Title marginBottom="8px">{pageContext.data.title}</Title>
      <Date>{pageContext.data.publishedAt}</Date>
      <Content>{pageContext.data.content}</Content>
    </Layout>
  )
}

export default ArticleTemplate

// export const query = graphql`
//   query ArticleQuery($shopifyId: String) {
//     shopifyArticle(shopifyId: { eq: $shopifyId }) {
//       shopifyId
//       title
//       publishedAt(formatString: "DD. MMMM YYYY", locale: "cs")
//       content
//     }
//   }
// `
