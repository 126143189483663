import styled from "styled-components"

const Title = styled.h2`
  font-size: 20px;
  margin-top: 15px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "16px")};

  &:before {
    content: "• ";
  }

  &:after {
    content: " •";
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-bottom: ${props =>
      props.marginBottom ? props.marginBottom : "42px"};

    font-size: 36px;
  }
`

export default Title
